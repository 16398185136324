import HomePage from "./HomePage";
import { APP_STORE_URL, GOOGLE_PLAY_URL } from "../utils/constants";
import { getMobileOperatingSystem } from "../utils/utils";

export default function MultiplayerPage() {
  console.log("multiplayer opened");
  const operatingSystem = getMobileOperatingSystem();
  if (operatingSystem === "Android") {
    // window.open(GOOGLE_PLAY_URL, "_blank")
    window.location.href = GOOGLE_PLAY_URL;
  } else if (operatingSystem === "iOS") {
    // window.open(APP_STORE_URL, "_blank")
    window.location.href = APP_STORE_URL;
  }

  return <HomePage />;
}
