import { APP_STORE_URL, GOOGLE_PLAY_URL } from "../utils/constants";
export default function HomePage() {
  return (
    <div className="App">
      <header className="App-header">
        <img
          src={require("../components/icon.png")}
          className="App-logo"
          alt="logo"
        />
        <a
          className="App-link"
          href="https://onelink.to/3s35hp"
          target="_blank"
          rel="noopener noreferrer"
        >
          Bottles Game Challenge
        </a>
        <p>
          The concept of the game is based on a challenge which is trending
          right now on social media.<br></br>
          We transformed this real life "bottle game challenge" into a mobile
          game to give people the opportunity to play with each other through
          multiplayer mode which we created in our game or even by themselves as
          we have a single player mode as well.
        </p>
        <p>
          Swap the bottles to match all the bottles!<br></br>
          You can request to remove your account data{" "}
          <a
            href="https://rention.net/delete-data-bottlesgame.html"
            className="App-link"
          >
            here
          </a>
          .<br></br>
          Read the{" "}
          <a
            href="https://rention.net/bottlesgame---privacy-policy.html"
            className="App-link"
          >
            privacy policy
          </a>
          .
        </p>
        <p>
          <p>
            <a href={GOOGLE_PLAY_URL}>
              <img
                src={require("../components/get_it_on_google_play.png")}
                className="google-play"
                alt="google-play"
              />
            </a>
          </p>
          <p>
            <a href={APP_STORE_URL}>
              <img
                src={require("../components/download_on_ios.png")}
                className="ios-store"
                alt="ios-store"
              />
            </a>
          </p>
        </p>
      </header>
    </div>
  );
}
