import { Router, Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./components/HomePage";
import MultiplayerPage from "./components/MultiplayerPage";

function App() {
  return (
      <Routes>
        <Route exact path="/"  element={<HomePage />} />
        <Route exact path="/multiplayer"  element={<MultiplayerPage />} />
        <Route exact path="*"  element={<MultiplayerPage />} />
      </Routes>
  );
}

export default App;
